// extracted by mini-css-extract-plugin
export var active = "product-consulting-module--active--422f7";
export var animatedDot = "product-consulting-module--animatedDot--5ba0b";
export var animatedDots = "product-consulting-module--animatedDots--44cba";
export var animatedDots__large = "product-consulting-module--animatedDots__large--c40d9";
export var animatedDots__small = "product-consulting-module--animatedDots__small--d79eb";
export var billetShadow = "product-consulting-module--billet-shadow--65bf8";
export var colorScheme__background__grayPale = "product-consulting-module--colorScheme__background__gray-pale--2c249";
export var consultingServicesList = "product-consulting-module--consultingServicesList--db843";
export var consultingServicesList__heading = "product-consulting-module--consultingServicesList__heading--60faf";
export var consultingServicesList__items = "product-consulting-module--consultingServicesList__items--587d7";
export var consultingServicesList__itemsWrapper = "product-consulting-module--consultingServicesList__itemsWrapper--360d6";
export var consultingServicesList__menu = "product-consulting-module--consultingServicesList__menu--27e2b";
export var countersBlock = "product-consulting-module--countersBlock--026b4";
export var firstBlock = "product-consulting-module--firstBlock--5377a";
export var firstBlock__img = "product-consulting-module--firstBlock__img--e4960";
export var goldButton = "product-consulting-module--goldButton--feb7c";
export var icomoon = "product-consulting-module--icomoon--eb51b";
export var imageBlock = "product-consulting-module--imageBlock--1db16";
export var imageContainer = "product-consulting-module--imageContainer--70a5e";
export var itConsulting = "product-consulting-module--itConsulting--276b5";
export var itConsultingBlock = "product-consulting-module--itConsultingBlock--7b173";
export var itConsultingBlock__dash = "product-consulting-module--itConsultingBlock__dash--6119f";
export var itConsultingBlock__headline = "product-consulting-module--itConsultingBlock__headline--20a10";
export var itConsultingBlock_flex = "product-consulting-module--itConsultingBlock_flex--affd2";
export var linkToForm = "product-consulting-module--linkToForm--e68f6";
export var lowercase = "product-consulting-module--lowercase--30da0";
export var pulse = "product-consulting-module--pulse--64bc6";
export var quote = "product-consulting-module--quote--b2ee4";
export var roundLinksMarker = "product-consulting-module--roundLinksMarker--87338";
export var roundLinksMenu = "product-consulting-module--roundLinksMenu--8c720";
export var roundLinksWrapper = "product-consulting-module--roundLinksWrapper--151be";
export var roundListMenu = "product-consulting-module--roundListMenu--f13ea";
export var serviceWrapper = "product-consulting-module--serviceWrapper--c3e6e";
export var steps = "product-consulting-module--steps--be26f";
export var textBlock = "product-consulting-module--textBlock--1beb5";
export var transition = "product-consulting-module--transition--d4187";
export var transitionBackground = "product-consulting-module--transition-background--109f2";
export var transitionReverse = "product-consulting-module--transition-reverse--69bd2";
export var uppercase = "product-consulting-module--uppercase--f7dbd";
export var withIcon = "product-consulting-module--withIcon--b91ae";