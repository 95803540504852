import React from "react"
import { Link } from "gatsby"

import * as styles from "../../assets/styles/product-consulting.module.scss"

const ConsultingServices = ({ service }) => (
  <div id={service.id} className={styles.serviceWrapper}>
    <div>
      <h4 className={styles.itConsulting}>{service.title}</h4>
      <div>
        <p>
          <strong>{service.strongText}</strong>
        </p>
        {service.text}
      </div>
      <div className={styles.steps}>
        <ul
          className={
            service.id === "DigitalBusinessDesign"
              ? styles.animatedDots__small
              : service.id === "Hypothesis"
              ? styles.animatedDots__large
              : styles.animatedDots
          }
        >
          {service.steps.map(step => (
            <li key={step}>
              <div className={styles.animatedDot} />
              <span>{step}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>

    <Link to="#contact-form" className={`goldFatLink ${service.linkClassName}`}>
      Get started
    </Link>
  </div>
)

export default ConsultingServices
