import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import cx from "classnames"
import CountUp from "react-countup"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import "../assets/styles/commonsGlossy.scss"
import "../assets/styles/commons/lists/_common__list__mdashed.scss"
import * as styles from "../assets/styles/product-consulting.module.scss"
import { IMG } from "../const"
import {
  CONSULTING_SERVICES,
  SERVICES_LINKS,
  ANIMATED_COUNTERS,
} from "../components/product-consulting/const"
import ConsultingServices from "../components/product-consulting/ConsultingServices"

const CaseStudies = loadable(() => import("../components/case-studies"))
const LeadingBlock = loadable(() =>
  import("../components/dedicated-team/LeadingBlock")
)
const ContactForm = loadable(() => import("../components/contact-form"))

const ItConsulting = () => {
  const [startCount, setStartCount] = useState(false)
  const [currentLocation, setCurrentLocation] = useState("")
  const [rotationTo, setRotationTo] = useState(0)
  const counterBlock = useRef(null)
  const countAnimationDuration = 5

  useEffect(() => {
    const hash = window.location.hash
    setCurrentLocation(hash)

    const handleStartCount = e => {
      const counterBlockOffset = counterBlock.current.getBoundingClientRect()
      const counterBlockPosition = counterBlockOffset.top - 200
      if (!startCount && counterBlockPosition < 0) {
        setStartCount(true)
      }
    }

    document.addEventListener("scroll", handleStartCount, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleStartCount)
    }
  }, [startCount])

  useEffect(() => {
    const menu = document.querySelectorAll(`.${styles.roundLinksWrapper}`)

    if (menu) {
      const handleScroll = e => {
        let curScroll = e.currentTarget.scrollY

        let links = document.querySelectorAll(
          `.${styles.roundLinksWrapper} li > a`
        )

        links.forEach(link => {
          let hash = link.dataset.hash
          let target = document.querySelector(`${hash}`)
          let topPos = target.getBoundingClientRect().top + window.scrollY

          if (
            topPos - 400 <= curScroll &&
            topPos + target.offsetHeight > curScroll
          ) {
            setCurrentLocation(hash)
            setTimeout(() => {
              onSetMarker()
            }, 0)
          }
        })
      }

      window.addEventListener("scroll", handleScroll, { passive: true })

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  })

  const onSetMarker = () => {
    if (currentLocation === "#Hypothesis") return setRotationTo(30)
    if (currentLocation === "#ProductStrategy") return setRotationTo(55)
    if (currentLocation === "#MVPAdviser") return setRotationTo(75)
    return setRotationTo(0)
  }

  return (
    <Layout
      pageTitle="Product Strategy Service"
      metaDescription="Boost your business with LaSoft's expert Product Strategy Service. Experience smart solutions for success in a competitive market."
    >
      {({ width }) => {
        return (
          <>
            <main className={cx(styles.itConsultingBlock, "glossy")}>
              <article>
                <div
                  className={cx(
                    "common__article__section",
                    styles.itConsultingBlock__headline
                  )}
                >
                  <h1>Product strategy service</h1>
                  <div className={styles.firstBlock}>
                    <div className={styles.firstBlock__img}>
                      <img
                        alt="Scheme"
                        src={`${IMG.PRODUCT_CONSULTING}/consulting-service.svg`}
                        loading="lazy"
                        width="346"
                        height="353"
                      />
                    </div>
                    <div>
                      <p>
                        <mark>
                          Our experts in System Architecture, Product Design,
                          Marketing, Planning, and Budgeting are ready to help
                          you achieve success.
                        </mark>
                      </p>
                      <p className={styles.linkToForm}>
                        <Link
                          to="#contact-form"
                          className={cx(
                            "goldFatLink product-free",
                            styles.goldButton
                          )}
                        >
                          Book a free consultation
                        </Link>
                      </p>
                      <p className={styles.withIcon}>
                        Make smarter decisions with LaSoft
                      </p>
                    </div>
                  </div>
                </div>

                <section className="common__article__section">
                  <p>
                    <mark
                      className={cx(
                        styles.itConsultingBlock__dash,
                        "common__textOutline"
                      )}
                    >
                      After 9 years of experience developing business IT
                      solutions across more than 85 projects in more than 19
                      international markets,{" "}
                      <strong>our professional teams</strong> have gained
                      experience in product strategy, design, development, and
                      launch.
                    </mark>
                  </p>
                </section>
                <section className="common__article__section">
                  <div className={styles.itConsultingBlock_flex}>
                    <ul className={styles.countersBlock} ref={counterBlock}>
                      {ANIMATED_COUNTERS.map(item => (
                        <li key={item.id}>
                          <strong>
                            {startCount && (
                              <CountUp
                                start={item.start}
                                end={item.end}
                                delay={item.delay}
                                duration={countAnimationDuration}
                                useEasing={false}
                                suffix={item.suffix ? item.suffix : ""}
                              >
                                {({ countUpRef }) => <ins ref={countUpRef} />}
                              </CountUp>
                            )}
                          </strong>
                          <span>{item.text}</span>
                        </li>
                      ))}
                    </ul>
                    <div className={styles.textBlock}>
                      <p>
                        We are a trusted technology partner for all of our
                        clients.
                      </p>
                      <div className={styles.imageBlock}>
                        <div className={styles.imageContainer}>
                          <picture>
                            <source
                              media="(max-width: 480px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_464.avif,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_928.avif 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.avif 3x`}
                              type="image/avif"
                            />
                            <source
                              media="(max-width: 480px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_464.webp,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_928.webp 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.webp 3x`}
                              type="image/webp"
                            />
                            <source
                              media="(max-width: 480px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_464.jpg,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_928.jpg 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.jpg 3x`}
                            />
                            <source
                              media="(min-width: 481px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_722.avif,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1444.avif 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_2166.avif 3x`}
                              type="image/avif"
                            />
                            <source
                              media="(min-width: 481px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_722.webp,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1444.webp 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_2166.webp 3x`}
                              type="image/webp"
                            />
                            <source
                              media="(min-width: 481px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_722.jpg,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1444.jpg 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_2166.jpg 3x`}
                            />

                            <source
                              media="(min-width: 901px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_464.avif,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_928.avif 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.avif 3x`}
                              type="image/avif"
                            />
                            <source
                              media="(min-width: 901px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_464.webp,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_928.webp 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.webp 3x`}
                              type="image/webp"
                            />
                            <source
                              media="(min-width: 901px)"
                              srcSet={`
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_464.jpg,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_928.jpg 2x,
														${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.jpg 3x`}
                            />
                            <img
                              alt="Anastasiya Tumanova, Head of Product Design Office"
                              src={`${IMG.PRODUCT_CONSULTING}/anastasia-tumanova_1392.jpg`}
                              loading="lazy"
                              width="464"
                              height="368"
                            />
                          </picture>
                        </div>
                      </div>
                      <dl>
                        <dt>Anastasiya Tumanova</dt>
                        <dd>
                          Head of Product Design Office
                          <p className={styles.quote}>
                            Based on these hard–earned figures, we help our
                            clients make smarter business decisions, define
                            winning product strategies, and build an accurate
                            roadmap.
                          </p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </section>

                <section className="common__article__section">
                  <div className={styles.consultingServicesList}>
                    <div className={styles.consultingServicesList__heading}>
                      <h3 className={styles.itConsulting}>
                        Consulting services
                      </h3>
                      <p>
                        Choose a consulting service for your business goals,
                        from Business Digital Design to MVP Adviser.
                      </p>
                    </div>
                    <div
                      className={styles.consultingServicesList__itemsWrapper}
                    >
                      {/* Mobile menu */}
                      <div className={styles.consultingServicesList__menu}>
                        <ul>
                          {SERVICES_LINKS.map((link, index) => (
                            <li
                              key={index}
                              className={
                                currentLocation === link.id ? styles.active : ""
                              }
                            >
                              <Link to={link.id} data-hash={link.id}>
                                {link.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {/* End of mobile menu */}
                      {/* Desktop menu */}
                      <div className={styles.roundListMenu}>
                        <div className={styles.roundLinksMenu}>
                          <div className={styles.roundLinksWrapper}>
                            <img
                              src={`${IMG.PRODUCT_CONSULTING}/icons/ellips_1.svg`}
                              alt="decoration"
                            />
                            <ul>
                              {SERVICES_LINKS.map((link, index) => (
                                <li
                                  key={index}
                                  className={
                                    currentLocation === link.id
                                      ? styles.active
                                      : ""
                                  }
                                >
                                  <Link to={link.id} data-hash={link.id}>
                                    {link.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                            <div
                              className={styles.roundLinksMarker}
                              style={{
                                transform: `rotate(${rotationTo}deg)`,
                              }}
                            >
                              <img
                                src={`${IMG.PRODUCT_CONSULTING}/icons/ellips_marker.svg`}
                                alt="decoration"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End of desktop menu */}
                      <div className={styles.consultingServicesList__items}>
                        {CONSULTING_SERVICES.map(service => (
                          <ConsultingServices
                            key={service.id}
                            service={service}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </section>

                <div className="commonGlossy__article__case-studies">
                  <CaseStudies title="" screenWidth={width} />
                </div>

                <LeadingBlock linkClassName="product-client" />
              </article>
            </main>
            <section className="footerForm">
              <div id="contact-form">
                <ContactForm formStatID="CommonFooter" />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default ItConsulting
