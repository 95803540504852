import React from "react"

export const ANIMATED_COUNTERS = [
  {
    id: 1,
    text: (
      <>
        Years in the IT market <br />
        <span>(of activity)</span>
      </>
    ),
    start: 0,
    end: 9,
    delay: 0,
  },
  {
    id: 2,
    text: "Projects we build",
    end: 85,
    delay: 0,
    suffix: "+",
  },
  {
    id: 3,
    text: "Cities worldwide",
    start: 0,
    end: 35,
    delay: 0,
  },
  {
    id: 4,
    text: "Industries Expertise",
    start: 0,
    end: 19,
    delay: 0,
  },
  {
    id: 5,
    text: (
      <>
        Multi-talented &amp; experienced <br />
        professionals on board
      </>
    ),
    start: 0,
    end: 120,
    delay: 0,
    suffix: "+",
  },
]

export const SERVICES_LINKS = [
  { id: "#DigitalBusinessDesign", title: <>Digital Business&nbsp;Design</> },
  {
    id: "#Hypothesis",
    title: <>Hypothesis definition&nbsp;&&nbsp;testing</>,
  },
  { id: "#ProductStrategy", title: "Product Strategy Development" },
  { id: "#MVPAdviser", title: "MVP Adviser" },
]

export const CONSULTING_SERVICES = [
  {
    id: "DigitalBusinessDesign",
    title: "Digital Business Design",
    steps: [
      "business model canvas",
      "value proposition canvas",
      "define and map your plan",
    ],
    strongText:
      "As part of the initial proposition, we will create a few tests you can run, ensuring that your business idea has true potential.",
    text: (
      <>
        <p>During the session, we will define:</p>
        <ul className="common__list__mdashed">
          <li>The Business Model Canvas</li>
          <li>The Value Proposition Canvas</li>
          <li>Define and map your hypothesis</li>
        </ul>
      </>
    ),
    linkClassName: "product-start",
  },
  {
    id: "Hypothesis",
    title: "Hypothesis Definition & Testing",
    steps: [
      "desirability hypotheses",
      "identify hypotheses",
      "prioritize hypotheses",
      "identify and prioritize riskiest hypotheses",
      "experiment",
      "metrics",
      "criteria",
      "insight",
    ],
    text: (
      <p>
        Hypotheses are the tools we use to prove or disprove our assumptions
        based on our client's goals, business model, data, and analysis.
        Hypothesis testing is a valuable method for gaining insights that can be
        the key to business success.
      </p>
    ),
    linkClassName: "product-start-two",
  },
  {
    id: "ProductStrategy",
    title: "Product Strategy Development",
    steps: [
      "discover the product competition",
      "defining the main problem the product solves",
      "defining important users",
      "creating a list of important features",
      "building a roadmap for the next 6-12 months",
    ],
    strongText:
      "With a product strategy, you will make more right business decisions.",
    text: (
      <>
        <p>
          You need a product strategy if you want to be more flexible and adapt
          quickly to these always-changing market conditions. Together with you,
          we'll adjust your business goals and product vision with the product
          development roadmap.
        </p>
        <p>
          On this basis, we are able to find various solutions to efficiently
          meet key business objectives and customers' needs.
        </p>
      </>
    ),
    linkClassName: "product-start-three",
  },
  {
    id: "MVPAdviser",
    title: "MVP Adviser",
    steps: [
      "market research",
      "product analysis",
      "competitors analysis",
      "product vision & scope",
      "system specification",
    ],
    strongText:
      "You have an idea for a tech business or startup, but you don't know the answer to the main question: how much?",
    text: (
      <p>
        How long? What technology? Our IT business consultants will discover
        your case and offer you the optimal solution and roadmap.
      </p>
    ),
    linkClassName: "product-start-four",
  },
]
